.cart {
    text-align: center;
    min-height: 75vh;
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.clearCart {
    color: white;
    background-color: rgb(199, 22, 45);
    padding: 14px;
    border: none;
    margin: 25px;
    border-radius: 6px;
    cursor: pointer;
    width: 30%;
}

.divButton {
    width: 100%;
}

.order {
    width: 100%;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    text-align: center;
    padding: 8px;
    border: 1px solid #00000034;
}

th {
    background-color: black;
    color: white;
}


@media screen and (max-width: 850px) {
    .cart {
        padding-top: 140px;
    }

    th {
        font-size: 11px;
    }

    td
    {
        font-size: 10px;
        padding: 2px;
    }
    .clearItem {
        color: white;
        background-color: rgb(54, 2, 9);
        padding: 10px 0; 
        margin: auto;
        width: auto;
    }
}