article{
    width: 22%;
    padding: 25px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 7px;
    margin: 15px 0px;
}

article img{
    width: 100%;
    height: 500px;
    max-width: 450px;
    padding-bottom: 15px;
}

.divProduct{
    max-width: 300px;
    border-top: 1px solid var(--gray);
    padding: 10px;
}

.category{
    color: var(--category);
    font-weight: 800;
    margin: 0;
    padding-top: 15px;
    font-size: 1rem;
    text-transform: capitalize;
}

.nameProduct{
    height: 82px;
    font-size: 1.1rem;
    margin: 0;
    padding-bottom: 7px;
}

.price{
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
}

.stock{
    font-size: 0.9rem;
    color: var(--gray);
    margin: 0;
    padding-left: 7px;
}


.btnDetail{
    border: 2px solid var(--dark);
    border-radius: 6px;
    color: var(--dark);
    font-weight: 800;
    font-family: monospace;
    font-size: 18px;
    text-align: center;
    height: 48px;
    width: 151px;
    box-shadow: 3px 0px 0px 0px var(--dark);
    margin-top: 12px;
    cursor: pointer;
    width: 100%;
    letter-spacing: 1px;
}

.btnDetail:hover{
    color: var(--white);
    box-shadow: 3px 0px 0px 0px var(--gray);
    background-color:var(--dark);
}

@media screen and (max-width: 850px) {
    article{
        width: 100%;
    }
}