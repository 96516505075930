nav{
    z-index: 100;
    position: fixed;
    width: 100%;
}
.navbar{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(0, 0, 0);
}

.navbar ul{
    list-style-type: none;
}

.navbar ul li{
    float: left;
}

.navbar ul li a,
.nav li a{
    display: block;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding: 14px 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
}

.navbar ul li a:hover,
.nav li a:hover{
    color: #b9a091;
}

.nav{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    background-color: rgb(10, 16, 33);
    box-shadow: 0px 15px 15px -11px rgb(0 0 0 / 20%);
    padding: 7px;
}

.logo{
    width: 230px;
}

.navbar > * {
    padding: 0px 30px;
}

.active{
    color: #b9a091 !important;
}

.banner{
    text-align: center;
    width: 100%;
}

.banner img{
    width: 1608px;
}

@media screen and (max-width: 850px) {
    .navbar > * {
        padding: 0;
    }
    .navbar{
    justify-content: center;
    height: 100px;
    }

    .navbar ul li a, .nav li a {
        font-size: 14px;
        padding: 0px 10px;
    }    
  }