.orderBtn{
    padding: 20px;
    margin-top: 5px;
    width: 50%;
}

form{
    border: 1px solid black;
    margin-top: 30px;
    padding-top: 13px;
}

form input{
    border: 1px solid gray;
    padding: 8px;
    margin: 7px 5px;
    border-radius: 3px;
    width: 200px;
}